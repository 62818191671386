<template>
  <el-form
    :rules="rules"
    :model="changeDirectiveModel"
    ref="elForm"
    label-width="110px">
    <el-form-item label="批次号：" prop="directiveBatchID">
      <el-input
        type="text"
        clearable
        v-model="changeDirectiveModel.directiveBatchID">
      </el-input>
    </el-form-item>
    <el-form-item label="批准文号：" prop="directive">
      <el-input
        type="text"
        clearable
        v-model="changeDirectiveModel.directive">
      </el-input>
    </el-form-item>
    <el-form-item label="变更令名称：" prop="directiveName">
      <el-input
        type="text"
        clearable
        v-model="changeDirectiveModel.directiveName">
      </el-input>
    </el-form-item>
    <el-form-item label="变更日期：" prop="directiveTime">
      <el-date-picker
        v-model="changeDirectiveModel.directiveTime"
        type="date"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        style="width: 100%;">
      </el-date-picker>
    </el-form-item>
    <template v-if="!changeDirectiveModel.resourceId">
      <el-form-item label="所属公司：" prop="companyGuid">
        <el-select v-model="companyGuid" clearable>
          <el-option
            v-for="company in companys"
            :key="company.companyGuid"
            :label="company.companyName"
            :value="company.companyGuid"
            >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属项目：" prop="projectGuid">
        <el-select v-model="projectGuid" clearable>
          <el-option
            v-for="project in projects"
            :key="project.projectGuid"
            :label="project.projectName"
            :value="project.projectGuid"
            >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属标段：" prop="tenderGuid">
        <el-select v-model="changeDirectiveModel.tenderGuid" clearable>
          <el-option
            v-for="tender in tenders"
            :key="tender.tenderGuid"
            :label="tender.tenderName"
            :value="tender.tenderGuid"
            >
          </el-option>
        </el-select>
      </el-form-item>
    </template>
    <template v-else>
      <el-form-item label="所属标段：">
        <el-input
          disabled
          :value="changeDirectiveModel.tenderName">
        </el-input>
      </el-form-item>
    </template>
    <el-form-item label="期数：" prop="periodName">
      <el-input
        type="text"
        clearable
        v-model="changeDirectiveModel.periodName">
      </el-input>
    </el-form-item>
    <el-form-item label="排序：" prop="sortId">
      <el-input-number
        v-model="changeDirectiveModel.sortId"
        :step="1"
        :min="0"
        :precision="0"
        step-strictly>
      </el-input-number>
    </el-form-item>
  </el-form>
</template>

<script>
import ChangeDirectiveModel from '@/model/ChangeDirectiveModel'
import GetTender from '@/mixins/GetTender'

export default {
  name: 'ChangeDirectiveForm',
  mixins: [
    GetTender
  ],
  props: {
    changeDirectiveModel: {
      type: ChangeDirectiveModel,
      default () {
        return new ChangeDirectiveModel()
      }
    }
  },
  data () {
    return {
      companyGuid: '',
      projectGuid: '',
      rules: {
        directiveBatchID: [
          {
            required: true,
            message: '请输入批次号',
            trigger: 'blur'
          }
        ],
        directive: [
          {
            required: true,
            message: '请输入变更令编号',
            trigger: 'blur'
          }
        ],
        directiveName: [
          {
            required: true,
            message: '请输入变更令名称',
            trigger: 'blur'
          }
        ],
        tenderGuid: [
          {
            required: true,
            message: '请选择标段',
            trigger: 'blur'
          }
        ],
        periodName: [
          {
            required: true,
            message: '请输入期数',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  watch: {
    companyGuid (newVal) {
      this.projectGuid = ''
      this.changeDirectiveModel.tenderGuid = ''
      this.projects = []
      this.tenders = []
      newVal && this._getProjects(newVal)
    },
    projectGuid (newVal) {
      this.changeDirectiveModel.tenderGuid = ''
      this.tenders = []
      newVal && this._getTenders(newVal)
    }
  },
  methods: {
    validate () {
      return this.$refs.elForm.validate
    }
  }
}
</script>

<style scoped lang="scss">
</style>
