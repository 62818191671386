import utility from '@/common/utility'
import auth from '@/common/auth'

export default class ChangeDirectiveModel {
  constructor (props) {
    if (props) {
      this.resourceId = props.changeDirectiveGuid
      this.changeDirectiveGuid = props.changeDirectiveGuid
      this.directiveBatchID = props.directiveBatchID
      this.directive = props.directive
      this.directiveName = props.directiveName
      this.directiveTime = props.directiveTime
      this.staffName = props.staffName
      this.sortId = props.sortId
      this.createUserGuid = props.createUserGuid
      this.createTime = props.createTime
      this.tenderGuid = props.tenderGuid
      this.tenderName = props.tenderName
      this.periodName = props.periodName
      this.fileName = props.fileName
    }
    this.init()
  }

  init () {
    if (!this.resourceId) this.resourceId = ''
    if (!this.changeDirectiveGuid) this.changeDirectiveGuid = ''
    if (!this.directiveBatchID) this.directiveBatchID = ''
    if (!this.directive) this.directive = ''
    if (!this.directiveName) this.directiveName = ''
    if (!this.directiveTime) this.directiveTime = ''
    if (!this.staffName) this.staffName = ''
    if (!this.sortId) this.sortId = 0
    if (!this.createUserGuid) this.createUserGuid = auth.getUserInfo().userGuid
    if (!this.createTime) this.createTime = utility.getCurrentTime()
    if (!this.tenderGuid) this.tenderGuid = ''
    if (!this.tenderName) this.tenderName = ''
    if (!this.periodName) this.periodName = ''
    if (!this.fileName) this.fileName = ''
  }

  generatePrimaryKey () {
    this.changeDirectiveGuid = utility.getUuid()
    this.resourceId = this.changeDirectiveGuid
  }
}
